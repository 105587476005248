import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { fetchData } from "./api/ClientFunction";
import { useAuth } from "./ContextAndHooks/AuthContext";
import { redirect } from "react-router-dom";
import { error } from "jquery";

const LeftSidebar = ({ gameStarted }) => {

 const { user } = useAuth();
  const phone = user?.phone;
  const [betType, setBetType] = useState(0);
  const [userBet, setUserBet] = useState([]);
  const [allBet, setAllBet] = useState([]);
  // const [history , setHistory] = useState ([])
  // function generateRandomBets(count) {
  //   const bets = [];

     
  //   // Function to generate a random phone number
  //   function generateRandomPhoneNumber() {
  //     const prefix = "600";
  //     const randomNumber = Math.floor(Math.random() * 100000000)
  //       .toString()
  //       .padStart(8, "0");
  //     return prefix + randomNumber;
  //   }

  //   // Function to generate a random date within a range
  //   function generateRandomDate(start, end) {
  //     return new Date(
  //       start.getTime() + Math.random() * (end.getTime() - start.getTime())
  //     );
  //   }

  //   for (let i = 1; i <= count; i++) {
  //     const id = i.toString();
  //     const phone = generateRandomPhoneNumber();
  //     // Generate betAmount rounded to nearest 10 between 50 and 250
  //     const betAmount = Math.round((Math.random() * 200 + 50) / 10) * 10;

  //     const betTime = generateRandomDate(new Date(2022, 0, 1), new Date()); // Random date between January 1, 2022, and today

  //     // Generate withdrawTime only 50% of the time
  //     const withdrawTime =
  //       Math.random() < 0.5 ? generateRandomDate(betTime, new Date()) : null;

  //     // Calculate withdrawAmount such that it is greater than betAmount and rounded to nearest 10
  //     const withdrawAmount = withdrawTime
  //       ? Math.round((betAmount + Math.random() * 150 + 10) / 10) * 10
  //       : 0;

  //     // Calculate a multiplier, assuming you need to introduce logic for it to be greater than 0 when withdrawAmount is greater than 0
  //     const multiplier =
  //       withdrawAmount > 0 ? (withdrawAmount / betAmount).toFixed(2) : 0;

  //     bets.push({
  //       id: id,
  //       phone: phone,
  //       betAmount: betAmount,
  //       withdrawAmount: withdrawAmount,
  //       multiplier: multiplier,
  //       betTime: betTime.toISOString(),
  //       withdrawTime: withdrawTime ? withdrawTime.toISOString() : null,
  //     });
  //   }

  //   return bets;
  // const reques}

  const baseUrl = process.env.REACT_APP_API_URL;
  

  function myBet(){
  console.log(phone , 'phone')
 
  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };
  
  fetch(`${baseUrl}/user/getmybets?phone=${phone}`, requestOptions)
    .then((response) => response.json())
    .then((result) => setUserBet(result.data))
    .catch((error) => console.error(error));
  
  }

  // Generate 25 random bets
  // const fakeBet = generateRandomBets(20);
  const handleBetTypeChange = (type) => {
    setBetType(type);
    myBet()
  };
  const { data } = {
    "status": true,
    "message": "Data Fetched Successfully...",
    "data": [
       {
            "id": 6,
            "phone": "8907897653",
            "betAmount": 10,
            "withdrawAmount": 15,
            "multiplier": 1.57,
            "betTime": "2024-07-03T05:28:06.107Z",
            "withdrawTime": "2024-07-03T05:28:15.454Z"
        },
        {
            "id": 6,
            "phone": "8907897653",
            "betAmount": 10,
            "withdrawAmount": 15,
            "multiplier": 1.57,
            "betTime": "2024-07-03T05:28:06.107Z",
            "withdrawTime": "2024-07-03T05:28:15.454Z"
        },
        {
            "id": 5,
            "phone": "8907897653",
            "betAmount": 10,
            "withdrawAmount": 11,
            "multiplier": 1.18,
            "betTime": "2024-07-03T05:27:49.674Z",
            "withdrawTime": "2024-07-03T05:27:58.215Z"
        },
        {
            "id": 4,
            "phone": "8907897653",
            "betAmount": 10,
            "withdrawAmount": 0,
            "multiplier": 0,
            "betTime": "2024-07-03T05:26:45.032Z",
            "withdrawTime": null
        },
        {
            "id": 3,
            "phone": "8907897653",
            "betAmount": 10,
            "withdrawAmount": 0,
            "multiplier": 0,
            "betTime": "2024-07-03T05:26:33.049Z",
            "withdrawTime": null
        },
        {
            "id": 2,
            "phone": "8907897653",
            "betAmount": 10,
            "withdrawAmount": 0,
            "multiplier": 0,
            "betTime": "2024-07-03T05:26:17.809Z",
            "withdrawTime": null
        },
        {
            "id": 1,
            "phone": "8907897653",
            "betAmount": 10,
            "withdrawAmount": 0,
            "multiplier": 0,
            "betTime": "2024-07-03T05:26:04.610Z",
            "withdrawTime": null
        }
    ]
};
  const { data: allbetData } = {
    "status": true,
    "message": "Data Fetched Successfully...",
    "data": [
      {
        "id": 46,
        "phone": "7062640973",
        "betAmount": 10000,
        "withdrawAmount": 15000,
        "multiplier": 1.5,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 45,
        "phone": "9982569696",
        "betAmount": 10,
        "withdrawAmount": 15,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 44,
        "phone": "8907897653",
        "betAmount": 450,
        "withdrawAmount": 1449,
        "multiplier": 3.22,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 43,
        "phone": "9602009911",
        "betAmount": 850,
        "withdrawAmount": 1334,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 42,
        "phone": "98640973",
        "betAmount": 650,
        "withdrawAmount": 1365,
        "multiplier": 2.1,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 41,
        "phone": "8907897653",
        "betAmount": 900,
        "withdrawAmount": 1413,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 40,
        "phone": "9929589994",
        "betAmount": 500,
        "withdrawAmount": 1315,
        "multiplier": 2.63,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 39,
        "phone": "7062696489",
        "betAmount": 50,
        "withdrawAmount": 78.5,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 38,
        "phone": "8907897653",
        "betAmount": 1350,
        "withdrawAmount": 3901.5,
        "multiplier": 2.89,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 37,
        "phone": "6362658954",
        "betAmount": 150,
        "withdrawAmount": 195,
        "multiplier": 1.30,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 36,
        "phone": "9887399788",
        "betAmount": 30,
        "withdrawAmount": 47.1,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 35,
        "phone": "9602009025",
        "betAmount": 1000,
        "withdrawAmount": 1570,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 34,
        "phone": "8907897653",
        "betAmount": 150,
        "withdrawAmount": 495,
        "multiplier":3.30,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 33,
        "phone": "8896526490",
        "betAmount": 50,
        "withdrawAmount": 78.5,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 32,
        "phone": "9787565214",
        "betAmount": 400,
        "withdrawAmount": 1240,
        "multiplier": 3.1,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 31,
        "phone": "9929635241",
        "betAmount": 300,
        "withdrawAmount": 690,
        "multiplier": 2.30,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 30,
        "phone": "8856524100",
        "betAmount": 650,
        "withdrawAmount": 1020.5,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 29,
        "phone": "8745129632",
        "betAmount": 20,
        "withdrawAmount": 31.4,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 28,
        "phone": "7985465214",
        "betAmount": 10,
        "withdrawAmount": 15,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 27,
        "phone": "9685741236",
        "betAmount": 500,
        "withdrawAmount": 785,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 26,
        "phone": "7885964578",
        "betAmount": 450,
        "withdrawAmount": 967.5,
        "multiplier": 2.15,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 25,
        "phone": "9685741458",
        "betAmount": 5000,
        "withdrawAmount": 7850,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 24,
        "phone": "8907897653",
        "betAmount": 3000,
        "withdrawAmount": 3330,
        "multiplier": 1.11,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 23,
        "phone": "8945788996",
        "betAmount": 10,
        "withdrawAmount": 15,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 22,
        "phone": "9602005874",
        "betAmount": 950,
        "withdrawAmount": 2042.5,
        "multiplier": 2.15,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 21,
        "phone": "9858478999",
        "betAmount": 850,
        "withdrawAmount": 943.5,
        "multiplier": 1.11,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },

      {
        "id": 20,
        "phone": "7898987800",
        "betAmount": 550,
        "withdrawAmount": 863.5,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 19,
        "phone": "9545857478",
        "betAmount": 200,
        "withdrawAmount": 430,
        "multiplier": 2.15,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 18,
        "phone": "6985478574",
        "betAmount": 100,
        "withdrawAmount": 157,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 17,
        "phone": "6896857485",
        "betAmount": 300,
        "withdrawAmount": 471,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 16,
        "phone": "7459865500",
        "betAmount": 650,
        "withdrawAmount": 13.65,
        "multiplier": 2.1,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 15,
        "phone": "9685744152",
        "betAmount": 3000,
        "withdrawAmount": 3330,
        "multiplier": 1.11,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 14,
        "phone": "8965478965",
        "betAmount": 770,
        "withdrawAmount": 1208.09,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 13,
        "phone": "8547859685",
        "betAmount": 10,
        "withdrawAmount": 15,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 12,
        "phone": "8985877488",
        "betAmount": 1570,
        "withdrawAmount": 3297,
        "multiplier": 2.1,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 11,
        "phone": "8907897653",
        "betAmount": 90,
        "withdrawAmount": 101.7,
        "multiplier": 1.13,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 10,
        "phone": "9989858574",
        "betAmount": 10,
        "withdrawAmount": 15,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 9,
        "phone": "9585748596",
        "betAmount": 1000,
        "withdrawAmount": 1570,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
      {
        "id": 8,
        "phone": "7485965445",
        "betAmount": 70,
        "withdrawAmount": 109,
        "multiplier": 1.57,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
    },
       {
        "id": 7,
        "phone": "8574859600",
        "betAmount": 130,
        "withdrawAmount": 169,
        "multiplier": 1.30,
        "betTime": "2024-07-03T05:28:06.107Z",
        "withdrawTime": "2024-07-03T05:28:15.454Z"
     },
        {
            "id": 6,
            "phone": "7485965877",
            "betAmount": 100,
            "withdrawAmount": 157,
            "multiplier": 1.57,
            "betTime": "2024-07-03T05:28:06.107Z",
            "withdrawTime": "2024-07-03T05:28:15.454Z"
        },
        {
            "id": 5,
            "phone": "8596458577",
            "betAmount": 50,
            "withdrawAmount": 59,
            "multiplier": 1.18,
            "betTime": "2024-07-03T05:27:49.674Z",
            "withdrawTime": "2024-07-03T05:27:58.215Z"
        },
        {
            "id": 4,
            "phone": "7859685488",
            "betAmount": 445,
            "withdrawAmount": 934.5,
            "multiplier":2.1 ,
            "betTime": "2024-07-03T05:26:45.032Z",
            "withdrawTime": null
        },
        {
            "id": 3,
            "phone": "9985748596",
            "betAmount": 1150,
            "withdrawAmount": 1805.05,
            "multiplier":1.57 ,
            "betTime": "2024-07-03T05:26:33.049Z",
            "withdrawTime": null
        },
        {
            "id": 2,
            "phone": "7788002200",
            "betAmount": 50,
            "withdrawAmount": 75,
            "multiplier": 1.57,
            "betTime": "2024-07-03T05:26:17.809Z",
            "withdrawTime": null
        },
        {
            "id": 1,
            "phone": "8907897653",
            "betAmount": 20,
            "withdrawAmount": 30,
            "multiplier": 1.5,
            "betTime": "2024-07-03T05:26:04.610Z",
            "withdrawTime": null
        },
        {
            "id": 1,
            "phone": "8907897653",
            "betAmount": 10,
            "withdrawAmount": 23.3,
            "multiplier": 2.33,
            "betTime": "2024-07-03T05:26:04.610Z",
            "withdrawTime": null
        }
    ]
};
 
  useEffect(() => {
    // console.log(allbetData);
    if (allbetData) {
      setAllBet(allbetData);
    }
  }, [allbetData]);

  function formatDate(inputDate) {
    const optionsDate = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    const optionsTime = {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    };

    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      optionsDate
    );
    const formattedTime = new Date(inputDate).toLocaleTimeString(
      "en-US",
      optionsTime
    );

    return `${formattedDate} ${formattedTime}`;
  }

  return (
    <div className="left-sidebar">
      <div className="tabs-navs">
        <div className="navigation">
          <div className="navigation-switcher">
            <div
              className={`slider  bet-btn ${betType === 0 ? "active" : ""}`}
              onClick={() => handleBetTypeChange(0)}
              
            >
              All Bets
            </div>
            <div
              className={`slider auto-btn ${betType === 1 ? "active" : ""}`}
              onClick={() => handleBetTypeChange(1)}
            >
              My Bets
            </div>

            <span className="active-line"></span>
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-between mx-5 my-2"
        style={{
          backgroundColor: "black",
          padding: "10px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(255, 255, 255, 0.1)",
        }}
      >
        <div style={{ color: "white" }}>
          <div style={{ fontSize: "14px", fontWeight: "bold" }}>All Bets</div>
          <div
            className="text-center"
            style={{ fontSize: "16px", fontWeight: "bold", lineHeight: "1.2" }}
          >
            {allBet.length }
            {/* {alert("hii")} */}
            
            
          </div>
        </div>
        <div
          style={{
            fontSize: "14px",
            cursor: "pointer",
            color: "yellowgreen",
            border: "2px solid white",
            padding: "6px 12px",
            borderRadius: "5px",
            boxShadow: "0 2px 5px rgba(0, 255, 0, 0.2)",
            transition: "all 0.3s ease",
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            lineHeight: "1",
          }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "rgba(255, 255, 255, 0.1)";
            e.target.style.color = "white";
            e.target.style.border = "2px solid yellowgreen";
            e.target.style.transform = "scale(1.05)";
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "transparent";
            e.target.style.color = "yellowgreen";
            e.target.style.border = "2px solid white";
            e.target.style.transform = "scale(1)";
          }}
          onClick={() => handleBetTypeChange(2)}
        >
          Previous Bets
        </div>
      </div>

      <div className="contents-blocks">
        <div>
          {/* left All Bets Code.... */}
          {betType === 0 && (
            <div style={{ overflowY: "scroll" }}>
              <div className="list-data-tbl mt-2">
                <div className="list-header">
                  <div className="column-1">Phone </div>
                  <div className="column-2">Bet</div>
                  <div className="column-3">Mult.</div>
                  <div className="column-4">Cash out</div>
                  <div className="ps-2"></div>
                </div>
                <div className="list-body scroll-div list-body1">
                  {allBet.map((item, index) => (
                    <div className="list-items" key={index}>
                      <div className="column-1 users fw-normal fw-bold ">
                        {item?.phone.charAt(0)}********
                        {item.phone.substring(item.phone.length - 2)}
                      </div>
                      <div className="column-2">
                        <button className="btn btn-transparent previous-history d-flex align-items-center mx-auto fw-normal">
                          {item?.betAmount}₹
                        </button>
                      </div>
                      <div className="column-3">
                        {gameStarted && (
                          <>
                            <div
                              className={`${
                                item?.multiplier === 0 ? "" : "bg3"
                              } custom-badge mx-auto`}
                            >
                              {item?.multiplier === 0
                                ? ""
                                : `${item?.multiplier}x`}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="column-2">
                        <button className="btn btn-transparent previous-history d-flex align-items-center mx-auto fw-normal">
                          {item?.withdrawAmount || 0}₹
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {/* right My Bets Code.... */}
          {betType === 1 && (
            <div style={{ overflowY: "scroll" }}>
              <div className="list-data-tbl mt-2">
                <div className="list-header">
                  <div className="column-1">Date</div>
                  <div className="column-2">Bet</div>
                  <div className="column-3">Mult.</div>
                  <div className="column-4">Cash out</div>
                  <div className="ps-2"></div>
                </div>
                <div className="list-body scroll-div list-body1">
                  {userBet.map((item, index) => (
                    <div className="list-items" key={index}>
                      <div className="column-1 users fw-normal">
                        {formatDate(item?.betTime)}
                      </div>
                      <div className="column-2">
                        <button className="btn btn-transparent previous-history d-flex align-items-center mx-auto fw-normal">
                          {item?.betAmount}₹
                        </button>
                      </div>
                      <div className="column-3">
                        <div className="bg3 custom-badge mx-auto">
                          {item?.multiplier}x
                        </div>
                      </div>
                      <div className="column-2">
                        <button className="btn btn-transparent previous-history d-flex align-items-center mx-auto fw-normal">
                          {item?.withdrawAmount || 0}₹
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {/* allprevios bets */}
          {betType === 2 && (
            <div style={{ overflowY: "scroll" }}>
              <div className="list-data-tbl mt-2">
                <div className="list-header" style={{ color: "yellowgreen" }}>
                  <div className="column-1 ">Phone</div>
                  <div className="column-2">Bet</div>
                  <div className="column-3">Mult.</div>
                  <div className="column-4">Cash out</div>
                  <div className="ps-2"></div>
                </div>
                <div className="list-body scroll-div list-body1">
                  {allBet.map((item, index) => (
                    <div className="list-items" key={index}>
                      <div className="column-1 users fw-normal fw-bold ">
                        {item?.phone.charAt(0)}********
                        {item.phone.substring(item.phone.length - 2)}
                      </div>

                      <div className="column-2">
                        <button className="btn btn-transparent previous-history d-flex align-items-center mx-auto fw-normal">
                          {item?.betAmount}₹
                        </button>
                      </div>

                      <div className="column-3">
                        {gameStarted && (
                          <>
                            <div
                              className={`${
                                item?.multiplier === 0 ? "" : "bg3"
                              } custom-badge mx-auto`}
                            >
                              {item?.multiplier === 0
                                ? ""
                                : `${item?.multiplier}x`}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="column-2">
                        <button className="btn btn-transparent previous-history d-flex align-items-center mx-auto fw-normal">
                          {item?.withdrawAmount || 0}₹
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeftSidebar;
